import React, { useState, useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { fetchDataIfNeeded } from "./actions";

import ThemeContext, { themeStyle } from "./context/themeContext";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Crashmap from "./components/Crashmap/Crashmap";
import Filter from "./components/Filter/Filter";
import CookieConsent from "./components/CookieConsent/CookieConsent";

import "./styles/App.scss";
import { RootState } from "./reducers";

const App = () => {
  const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

  //using react context
  const darkPreffered =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useState(
    darkPreffered ? themeStyle.dark : themeStyle.light
  );
  const toggleTheme = () => {
    setTheme(theme === themeStyle.light ? themeStyle.dark : themeStyle.light);
  };

  const [filterOpen, setFilterOpen] = useState(false);
  function toggleFilter(e: React.MouseEvent) {
    e.preventDefault();
    setFilterOpen(!filterOpen);
  }

  useEffect(() => {
    dispatch(fetchDataIfNeeded());
  }, [dispatch]);

  return (
    <CookiesProvider>
      <ThemeContext.Provider value={theme}>
        <div
          className={`cm-app-container d-flex flex-column ${theme.mainContainer.className}`}
        >
          <Navbar toggleFilter={toggleFilter} />

          <div className="flex-grow-1 d-flex flex-column">
            <div className="container-fluid h-100 px-0">
              <div className="row h-100 no-gutters">
                <Crashmap />
                <Filter filterOpen={filterOpen} toggleFilter={toggleFilter} />
              </div>
            </div>
          </div>

          <Footer onThemeSwitcherClick={toggleTheme} />
          <CookieConsent />
        </div>
      </ThemeContext.Provider>
    </CookiesProvider>
  );
};

export default App;
