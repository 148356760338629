import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

import ThemeContext, { style } from "../../context/themeContext";
import L from "leaflet";
import { drawCircle, drawHeatmap, fitBounds } from "./mapUtils";

import Control from "react-leaflet-control";
import { Map, TileLayer } from "react-leaflet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCrosshairs } from "@fortawesome/free-solid-svg-icons";

import "./Crashmap.scss";

const Crashmap = () => {
  const { isFetching, formSelects, mapData } = useSelector(
    (state: RootState) => state.mapDataReducer
  );

  let mapCenter = L.latLng(55.2, 24);
  let mapZoom = 8;
  const maxZoom = 18;
  const myLocationZoom = 14;

  const linkHref = "#";

  type overlayLayers = "Taškai" | "HeatMap";

  const mapRef = useRef<Map>(null);
  const visibleLayerRef = useRef<overlayLayers>();

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement,
        circlesLayer = L.featureGroup(),
        heatmapLayer = L.featureGroup();

      //Taškai/HeatMap switch
      const overlayMaps = {
        Taškai: circlesLayer,
        HeatMap: heatmapLayer,
      };

      //@ts-ignore
      const layersControl = L.control.layers(overlayMaps).addTo(map);

      if (!visibleLayerRef.current) {
        map.addLayer(circlesLayer);
      } else {
        map.addLayer(overlayMaps[visibleLayerRef.current]);
      }

      map.on("baselayerchange", function (e: L.LayersControlEvent) {
        visibleLayerRef.current = e.name as overlayLayers;
      });

      //add location control event
      map.on("locationfound", (e: L.LocationEvent) => {
        try {
          map.setView(e.latlng, myLocationZoom);
        } catch (err) {
          console.error(err);
        }
      });

      //render mapData
      if (mapData) {
        let heatData = [];
        console.time("Crashmap > render circles");
        for (const crash of mapData) {
          const { lat, lng } = crash;
          if (lat && lng) {
            drawCircle(circlesLayer, crash, formSelects);
            heatData.push([
              lat,
              lng,
              0.2 *
                (parseInt(crash.suzeistu_skaicius) +
                  parseInt(crash.zuvusiu_skaicius)),
            ]); //build heatData
          }
        }
        console.timeEnd("Crashmap > render circles");

        drawHeatmap(map, heatmapLayer, heatData);
        fitBounds(map, circlesLayer, maxZoom);
      }

      //like componentWillUnmount
      return () => {
        circlesLayer.clearLayers();
        heatmapLayer.clearLayers();
        map.removeControl(layersControl);
        map.off("baselayerchange");
      };
    } // if mapRef.current
  }, [mapData, formSelects]);

  const handleLocate = (e: React.MouseEvent) => {
    e.preventDefault();
    mapRef.current && mapRef.current.leafletElement.locate({ setView: false });
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div className="col-md cm-map" id="map">
          <Map
            className="cm-map h-100"
            preferCanvas={true}
            center={mapCenter}
            zoom={mapZoom}
            ref={mapRef}
          >
            <TileLayer
              attribution='&copy; <a href="http://cartodb.com/attributions#basemaps">CartoDB</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
              url={`https://cartodb-basemaps-{s}.global.ssl.fastly.net/${theme.map.tiles}_all/{z}/{x}/{y}.png`}
            />
            {document.location.protocol === "https:" && (
              <Control
                position="topleft"
                //@ts-ignore
                className="leaflet-bar leaflet-control"
              >
                <a
                  href={linkHref}
                  className="leaflet-bar-part leaflet-bar-part-single"
                  onClick={handleLocate}
                >
                  <FontAwesomeIcon icon={faCrosshairs} />
                </a>
              </Control>
            )}
            {
              <Control
                position="bottomright"
                //@ts-ignore
                className="leaflet-control-layers leaflet-control cm-legend"
              >
                <div>
                  <span
                    className="cm-circle"
                    style={{ backgroundColor: style.circles.pedestrian }}
                  ></span>{" "}
                  Užvažiavimas ant pėsčiojo
                </div>
                <div>
                  <span
                    className="cm-circle"
                    style={{ backgroundColor: style.circles.bicycle }}
                  ></span>{" "}
                  Susidūrimas su dviračiu
                </div>
                <div>
                  <span
                    className="cm-circle"
                    style={{ backgroundColor: style.circles.default }}
                  ></span>{" "}
                  Visi kiti įvykiai
                </div>
                <div>
                  <span className="cm-circle dead"></span> Yra žuvusiu
                </div>
              </Control>
            }
          </Map>
          {isFetching && (
            <div className={`cm-maploding d-flex ${theme.map.overlay}`}>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="2x"
                color={theme.map.spin}
              />
            </div>
          )}
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default Crashmap;
